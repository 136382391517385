import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/home/runner/work/kernel-v2/kernel-v2/src/modules/layouts/default_layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const Link = makeShortcode("Link");
const Column = makeShortcode("Column");
const Box = makeShortcode("Box");
const Video = makeShortcode("Video");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "️-scaling-principled-games",
      "style": {
        "position": "relative"
      }
    }}>{`⚖️ Scaling Principled Games`}<a parentName="h1" {...{
        "href": "#%EF%B8%8F-scaling-principled-games",
        "aria-label": "️ scaling principled games permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h1>
    <p>{`It's easy to lose your bearings when trying to conceive of a planetary-scale, decentralized, `}<a parentName="p" {...{
        "href": "/learn/module-1/"
      }}>{`networked virtual machine`}</a>{` which provides a shared, ownerless computing surface on which we can run the `}<a parentName="p" {...{
        "href": "/learn/module-2/"
      }}>{`global financial system`}</a>{`; `}<a parentName="p" {...{
        "href": "/learn/module-3"
      }}>{`reimagine the web`}</a>{` and our `}<a parentName="p" {...{
        "href": "/learn/module-4"
      }}>{`organisational customs`}</a>{`; alter the `}<a parentName="p" {...{
        "href": "/learn/module-5"
      }}>{`incentives which power society`}</a>{` and ultimately create the interpersonal `}<a parentName="p" {...{
        "href": "/learn/module-0/trust"
      }}>{`trust`}</a>{` required to `}<a parentName="p" {...{
        "href": "/learn/module-0/play-of-pattern"
      }}>{`play`}</a>{` `}<a parentName="p" {...{
        "href": "https://getdweb.net/principles"
      }}>{`principled`}</a>{`, infinite games with each other.`}</p>
    <p>{`Last week we asked you what constitutes `}<a parentName="p" {...{
        "href": "/learn/module-5/incentives/#persuasion"
      }}>{`persuasion`}</a>{`
and argued that it is language which performs what it says. We've highlighted how economic
code which distributes `}<a parentName="p" {...{
        "href": "/learn/module-1/value/"
      }}>{`value`}</a>{` by virtue of how it is expressed
constitutes a `}<a parentName="p" {...{
        "href": "/learn/module-4/self-enquiry/#signature-performances"
      }}>{`new class of performative speech acts`}</a>{`
of a most `}<a parentName="p" {...{
        "href": "/learn/module-1/meaning/"
      }}>{`meaningful`}</a>{` kind. Hopefully, you're beginning to see how
this course is itself a kind of chain: each module `}<a parentName="p" {...{
        "href": "/learn/module-3/lock-it-open/#the-path-forward"
      }}>{`linked`}</a>{`
in ever greater detail to the `}<a parentName="p" {...{
        "href": "/learn/module-3/time/"
      }}>{`ones before`}</a>{`; each piece within each
week `}<a parentName="p" {...{
        "href": "/learn/module-2/debt/"
      }}>{`indebted`}</a>{` to `}<a parentName="p" {...{
        "href": "/learn/module-4/the-garden"
      }}>{`many others`}</a>{`
which `}<a parentName="p" {...{
        "href": "/learn/module-3/remember/"
      }}>{`transform it`}</a>{` from something which stands alone into part of
a bigger, `}<a parentName="p" {...{
        "href": "/learn/module-4/art/"
      }}>{`digital`}</a>{` `}<a parentName="p" {...{
        "href": "/learn/module-0/purpose/"
      }}>{`picture`}</a>{`.`}</p>
    <h2 {...{
      "id": "mirror-play",
      "style": {
        "position": "relative"
      }
    }}>{`Mirror Play`}<a parentName="h2" {...{
        "href": "#mirror-play",
        "aria-label": "mirror play permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <p>{`This week is an opportunity for simple `}<a parentName="p" {...{
        "href": "/learn/module-3/humility/"
      }}>{`reflection`}</a>{`. Kernel is
built around "ever-green" content and so, while there is a great deal of information about
scaling and staking `}<Link to="https://hackmd.io/@benjaminion/eth2_news" mdxType="Link">{`available`}</Link>{`,
we'll only cover the Ethereum 2.0 Design Rationale and
the `}<a parentName="p" {...{
        "href": "/learn/module-1/joyful-subversion/#experiment-with-format"
      }}>{`Bret Victor video you were promised in Week 1`}</a>{`,
both balanced by an old Spanish poetic idea. `}</p>
    <p>{`Take your time, enjoy the serene pace, and let's co-create some beautifully principled games to
play with one another.`}</p>
    <blockquote>
      <p parentName="blockquote">{`When you learn something, you should be able to teach it to people. You should put the same effort into teaching as into learning. And if you want to teach, you should be humble enough to learn something. Then you can teach. If you try to teach just because you know something, you cannot teach anything. When you are ready to be taught by someone, then, if necessary, you can teach people in the true sense of the word. So, to learn is to teach and to teach is to learn." - Shunryu Suzuki`}</p>
    </blockquote>
    <h2 {...{
      "id": "week-6-firesides",
      "style": {
        "position": "relative"
      }
    }}>{`Week 6 Firesides`}<a parentName="h2" {...{
        "href": "#week-6-firesides",
        "aria-label": "week 6 firesides permalink",
        "className": "anchor-link after"
      }}><span parentName="a">{`¶`}</span></a></h2>
    <Column sx={{
      gap: "10%"
    }} mdxType="Column">
      <Box mdxType="Box">
        <p><strong parentName="p">{`Jasmine Wang & Daanish Shabbir - November 11, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/GdF5ps3okgA?start=244" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Andy Tudhope - July 1, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/0BSPiWiXffQ" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Vitalik Buterin - Feb 25, 2021`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/xpKEVtjBNYg" mdxType="Video" />
      </Box>
      <Box mdxType="Box">
        <p><strong parentName="p">{`Dany Ryan - Aug 20, 2020`}</strong></p>
        <Video src="https://www.youtube-nocookie.com/embed/0WeQ7c-RbwM?start=1007" mdxType="Video" />
      </Box>
    </Column>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      